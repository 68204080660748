
var cart = require('base/cart/cart');
var tealiumEnable = require('int_tealium_sfra/tealiumEnable');
var giftBoxOptionHandler = require('../giftBoxHelper');
var updateMiniCart = true;
var prodId;
var maxQuantityAllowed;
var disabledColor = window.getComputedStyle(document.body).getPropertyValue('--bag-btn-color-disabled');
var disabledBackground = window.getComputedStyle(document.body).getPropertyValue('--bag-btn-bg-disabled');
var utils = require('../utils');


/**
 * Gets the screen size of the viewport
 *  @return {boolean} isMobileOrTablet flag
 */
function getViewPortsize() {
    var screenSize = $(window).width();
    var isMobileOrTablet;
    if (screenSize <= 991) {
        isMobileOrTablet = true;
    } else if (screenSize > 991) {
        isMobileOrTablet = false;
    }
    return isMobileOrTablet;
}

/**
 * Gets the updates updateMinibagTop
 */
function updateMinibagTop() {
    var top_of_element = $('.header').offset().top;
    var bottom_of_element = $('.header').offset().top + $('.header').outerHeight();
    var bottom_of_screen = $(window).scrollTop() + $(window).innerHeight();
    var top_of_screen = $(window).scrollTop();
    if ((bottom_of_screen > top_of_element) && (top_of_screen < bottom_of_element)) {
        $('.minicart .minipopover-bag').css('top', '0');
    } else {
        $('.minicart .minipopover-bag').css('top', '80px');
    }
}

/**
 * Show mini cart
 * @param {boolean} isMobile is mobile flag
 */
function miniCartOpen(isMobile) {
    if (!isMobile) {
        var overlayHtml = '<div class="overlay"></div>';
        $(overlayHtml).prependTo('.minicart .minipopover-bag').fadeIn();
    }

    $('.minicart .minipopover-bag').addClass('open');
    $('.minipopover-bag .asset-close-icon').trigger('focus');
    $('.page').addClass('minibag-open');
    if (!isMobile) {
        $('html').addClass('hide-scroll-and-lock');
    }
}

/**
 * Handles Minicart Close
 */
function miniCartClose() {
    $('.minicart .overlay').fadeOut();
    $('.minicart .minipopover-bag').removeClass('open');
    $('html').removeClass('hide-scroll-and-lock');
    setTimeout(() => {
        $('.page').removeClass('minibag-open');
    }, 500);
}

/**
 * Remove product lineitem when cart item count changed to zero
 * @param {string} url is cart remove product lineitem url
 * @param {string} uuid is unique itentifier
 * @param {number} pid is product ID
 */
function miniCartZeroItemCount(url, uuid, pid) {
    if (pid && uuid) {
        $.ajax({
            url: url + '?pid=' + pid + '&uuid=' + uuid + '&pageType=minicart',
            method: 'GET',
            success: function (data) {
                $('.minicart__product_container').empty().html(data.renderTemplate);
                $('#lineItem' + uuid).remove();
                // eslint-disable-next-line
                $('.mini-cart-grand-total').text(typeof(data.basket.totals.subTotalWithoutOrderDiscount) == 'undefined' ? data.basket.totals.subTotal : data.basket.totals.subTotalWithoutOrderDiscount);
                $('.js-quantity-minibag').text(data.basket.numItems);
                $('.items-label').text(utils.pluralize(data.basket.numItems, 'Item'));
                $('.minicart-link').attr('title', 'Cart ' + data.basket.numItems + ' Items');
                $('.removed-' + uuid).removeClass('d-none');
                var bagCount = data.basket.numItems;
                if (bagCount === 0) {
                    $('.icon-cart-container').html('<span class="icon-bag"></span>');
                    $('.total-count-minicart .minicart-number, .total-count-minicart-mobile .minicart-number').addClass('mincart-noitems');
                    $('.total-count-minicart .minicart-number')[0].innerText = $('.total-count-minicart-mobile .minicart-number')[0].innerText = '';
                    $('.mini-cart-grand-total').text('$0');
                    $('.minicart .checkout-btn').css('pointer-events', 'none');
                    $('.minicart .checkout-btn').css('color', disabledColor);
                    $('.minicart .checkout-btn').css('background-color', disabledBackground);
                    $('.minicart .checkout-btn').attr('aria-disabled', true);
                }

                utils.updateCartCount(data.basket.numItems);
                updateMiniCart = true;
                tealiumEnable.getAnalyticsData('miniCartAddOrSubtractEvent', data, { pid: pid, quantityUpdate: '-1' });
            }
        });
    }
}

/**
 * method to display the error messsage while updating quantity
 * @param {Object} err - err
 * @param {string} uuid - uuid
 */
function showErrorMessage(err, uuid) {
    if (err.responseJSON.message) {
        let errorHtml = `${err.responseJSON.message} <a class="dismiss-alert-minibag" href="javascript:void(0);">Dismiss</a>`;
        let $errorContainer = $('.invalid-qty-error-' + uuid);
        $errorContainer.find('.invalid-qty-error-text').empty().html(errorHtml);
        $errorContainer.removeClass('d-none');
    }
    tealiumEnable.getAnalyticsData('serverError', err.responseJSON.errorCode);
}

module.exports = {
    ...cart,
    getViewPortsize: getViewPortsize,
    miniCartZeroItemCount: miniCartZeroItemCount,

    getPids: function () {
        $(document).on('click', 'button.add-to-cart, button.add-to-cart-global', function () {
            prodId = $(this).attr('data-cur-pid');
            updateMiniCart = true;
        });
    },

    alertDismiss: function () {
        $(document).on('click', '.dismiss-alert-minibag', function () {
            $('.max-quant-error').addClass('d-none');
        });
    },

    miniCartAdd: function () {
        $('.minicart').on('click', '.mini-quant-add', function () {
            maxQuantityAllowed = $(this).siblings('.minicart-quant-input').attr('data-max-allowed');
            $('.minicart-removed-msg').addClass('d-none');
            var uuid = $(this).data('uuid');
            var url = $(this).data('action');
            var pid = $(this).data('pid');
            var $this = $(this);
            let $errorContainer = $('.invalid-qty-error-' + uuid);
            $('.mini-quant-sub').css('color', '#fff');
            var curQty = 0;
            var allowAjax = true;
            if ((+$(this).prev().val()) <= (+maxQuantityAllowed - 1)) {
                curQty = (+$(this).prev().val() + 1);
                $(this).prev().val(+$(this).prev().val() + 1);
                $('.max-error-' + uuid).addClass('d-none');
                $(this).css('color', '#fff');

                //  Updating label increase/decrese quantity
                let labelText = $(this).data('label');
                $(this).find('span').html(labelText + ' ' + curQty);
                var $qtyAdd = $(this).closest('.quantity-select-minicart').find('.mini-quant-sub');
                $qtyAdd.find('span').html($qtyAdd.data('label') + ' ' + curQty);
            } else {
                allowAjax = false;
                if (!$errorContainer.hasClass('d-none')) {
                    $errorContainer.addClass('d-none');
                }
                $(this).prop('disabled', true);
                $('.max-error-' + uuid).removeClass('d-none');
                $(this).css('opacity', '0.7');
            }

            if (curQty !== 0 && allowAjax) {
                $.ajax({
                    url: url + '?pid=' + pid + '&quantity=' + curQty + '&uuid=' + uuid + '&pageType=minicart',
                    method: 'GET',
                    success: function (data) {
                        $('.minicart__product_container').empty().html(data.renderTemplate);
                        // eslint-disable-next-line
                        $('.mini-cart-grand-total').text(typeof(data.totals.subTotalWithoutOrderDiscount) == 'undefined' ? data.totals.subTotal : data.totals.subTotalWithoutOrderDiscount);
                        $('.js-quantity-minibag').text(data.numItems);
                        $('.items-label').text(utils.pluralize(data.numItems, 'Item'));
                        $('.minicart-link').attr('title', 'Cart ' + data.numItems + ' Items');
                        utils.updateCartCount(data.numItems);
                        $('.removed-' + uuid).addClass('d-none');
                        data.items.forEach(elem=>{
                            $('#qty-' + elem.UUID).attr('data-max-allowed', elem.maxQuantityAllowed);
                            $(`.price-update-on-add-${elem.UUID}`).text(elem.priceTotal.nonAdjustedPrice.displayPrice);
                            $('#itemprice-' + elem.UUID).text(elem.priceTotal.price.displayPrice);
                        });
                        updateMiniCart = true;
                        tealiumEnable.getAnalyticsData('miniCartAddOrSubtractEvent', data, { pid: pid, quantityUpdate: '1' });
                    },
                    error: function (err) {
                        $this.prev().val(+$this.prev().val() - 1);
                        showErrorMessage(err, uuid);
                    }
                });
            }
            updateMiniCart = true;
        });
    },
    miniCartSub: function () {
        $('.minicart').on('click', '.mini-quant-sub', function () {
            var curQty = 0;
            var $this = $(this);
            var uuid = $(this).data('uuid');
            var url = $(this).data('action');
            var pid = $(this).data('pid');
            var allowRemoveAjax = false;
            $('.minicart-removed-msg').addClass('d-none');
            $('.max-error-' + uuid).addClass('d-none');
            $('.mini-quant-add').prop('disabled', false);
            $('.mini-quant-add').css('cursor', 'pointer');
            $('.mini-quant-add').css('opacity', '1');
            if ((+$(this).next().val()) === 1) {
                allowRemoveAjax = true;
                url = $(this).data('action-remove');
                $(this).css('color', '#696969');
                miniCartZeroItemCount(url, uuid, pid);
            }

            if ((+$(this).next().val()) > 1) {
                allowRemoveAjax = false;
                $(this).css('color', '#fff');
                $('.minicart-removed-msg').addClass('d-none');
                url = $(this).data('action');
                curQty = (+$(this).next().val() - 1);
                $(this).next().val(+$(this).next().val() - 1);

                //  Updating label increase/decrese quantity
                var labelText = $(this).data('label');
                $(this).find('span').html(labelText + ' ' + curQty);
                var $qtyAdd = $(this).closest('.quantity-select-minicart').find('.mini-quant-add');
                $qtyAdd.find('span').html($qtyAdd.data('label') + ' ' + curQty);
            }

            if (curQty !== 0 && !allowRemoveAjax) {
                $.ajax({
                    url: url + '?pid=' + pid + '&quantity=' + curQty + '&uuid=' + uuid + '&pageType=minicart',
                    method: 'GET',
                    success: function (data) {
                        $('.minicart__product_container').empty().html(data.renderTemplate);
                        // eslint-disable-next-line
                        $('.mini-cart-grand-total').text(typeof(data.totals.subTotalWithoutOrderDiscount) == 'undefined' ? data.totals.subTotal : data.totals.subTotalWithoutOrderDiscount);
                        $('.js-quantity-minibag').text(data.numItems);
                        $('.items-label').text(utils.pluralize(data.numItems, 'Item'));
                        $('.minicart-link').attr('title', 'Cart ' + data.numItems + ' Items');
                        utils.updateCartCount(data.numItems);
                        data.items.forEach(elem=>{
                            $('#itemprice-' + elem.UUID).text(elem.priceTotal.price.displayPrice);
                            $(`.price-update-on-add-${elem.UUID}`).text(elem.priceTotal.nonAdjustedPrice.displayPrice);
                        });
                        updateMiniCart = true;
                        tealiumEnable.getAnalyticsData('miniCartAddOrSubtractEvent', data, { pid: pid, quantityUpdate: '-1' });
                    },
                    error: function (err) {
                        $this.next().val(+$this.next().val() + 1);
                        showErrorMessage(err, uuid);
                    }
                });
                updateMiniCart = true;
            }
        });
    },

    miniCartRemove: function () {
        $('.minicart').on('click', '.mini-cart-remove', function () {
            var uuid = $(this).data('uuid');
            var url = $(this).data('action');
            var pid = $(this).data('pid');
            $('.minicart-removed-msg').addClass('d-none');
            $.ajax({
                url: url + '?pid=' + pid + '&uuid=' + uuid + '&pageType=minicart',
                method: 'GET',
                success: function (data) {
                    var subTotalPrice = data.basket.totals && data.basket.totals.subTotalWithoutOrderDiscount ? data.basket.totals.subTotalWithoutOrderDiscount : data.basket.totals.subTotal;
                    $('#lineItem' + uuid).remove();
                    $('.mini-cart-grand-total').text(subTotalPrice);
                    $('.js-quantity-minibag').text(data.basket.numItems);
                    $('.items-label').text(utils.pluralize(data.basket.numItems, 'Item'));
                    $('.minicart-link').attr('title', 'Cart ' + data.basket.numItems + ' Items');
                    var bagCount = data.basket.numItems;
                    utils.updateCartCount(bagCount);
                    giftBoxOptionHandler.giftBoxHelper(data.basket.items, '.minicart-gift-box');
                    $('.removed-' + uuid).removeClass('d-none');
                    $('.minicart__product_container').empty().html(data.renderTemplate);
                    if (!data.basket.hasBonusProduct) {
                        $('.free-product').remove();
                    }
                    if (bagCount === 0) {
                        $('.minicart-gift-box').addClass('d-none');
                        $('.removed-' + uuid).addClass('d-none');
                        $('.icon-cart-container').html('<span class="icon-bag"></span>');
                        $('.total-count-minicart .minicart-number, .total-count-minicart-mobile .minicart-number').addClass('mincart-noitems');
                        $('.total-count-minicart .minicart-number')[0].innerText = $('.total-count-minicart-mobile .minicart-number')[0].innerText = '';
                        $('.minicart-empty-bag').removeClass('d-none');
                        $('.desk-product-contents').empty();
                        $('.mini-cart-grand-total').text('$0');
                        $('.mini-cart-grand-total').attr('aria-label', '0$');
                        $('.minicart .checkout-btn').css('pointer-events', 'none');
                        $('.minicart .checkout-btn').css('color', disabledColor);
                        $('.minicart .checkout-btn').css('background-color', disabledBackground);
                        $('.minicart .checkout-btn').attr('aria-disabled', true);
                    }
                    tealiumEnable.getAnalyticsData('miniCartRemoveAnalytics', data.deletedPliAnalyticsData);
                }
            });
            updateMiniCart = true;
        });
    },

    miniCartClose: function () {
        $('.minicart').on('click', '.js-minicart-close', function () {
            miniCartClose();
            var isMob = getViewPortsize();
            if (isMob) {
                $('.carousel-indicators').css('z-index', '15');
            } else {
                $('body').css('overflow-x', 'hidden');
            }

            $('.minicart .minipopover-bag').removeClass('open');
        });
    },

    miniCartUpdate: function () {
        $('.minicart').on('count:update', function () {
            $('body').trigger('product:Added', {pid: prodId});
        });
    },

    clickOutToClose: function () {
        $('.minicart .minipopover-bag').on('click', function (e) {
            if ($('.minicart__outerCont').has(e.target).length <= 0) {
                miniCartClose();
                $('.carousel-indicators').css('z-index', '15');
            }
        });
    },

    clickBagToOpen: function () {
        $('.minicart').on('click', '.js-minicartdesk-link', function () {
            var isMob = getViewPortsize();
            var url = $(this).data('action-url');
            var isCart = $('body').find('.cart-page').length > 0;


            if (!isMob && !isCart) {
                setTimeout(function () {
                    updateMinibagTop();
                    $.get(url, function (data) {
                        $('.minicart .minipopover-bag').empty();
                        $('.minicart .minipopover-bag').append(data);
                        miniCartOpen();
                        var isDeskEmpty = ($('.desk-product-contents').children().length === 0);
                        if (isDeskEmpty) {
                            $('.minicart-empty-bag').removeClass('d-none');
                            $('.mini-cart-grand-total').text('$0');
                            $('.mini-cart-grand-total').attr('aria-label', '0$');
                            $('.minicart .checkout-btn').css('pointer-events', 'none');
                            $('.minicart .checkout-btn').css('color', disabledColor);
                            $('.minicart .checkout-btn').css('background-color', disabledBackground);
                            $('.minicart .checkout-btn').attr('aria-disabled', true);
                        }
                        updateMiniCart = false;
                    });
                }, 400);
            }
        });
    },

    miniAfterProdAdd: function () {
        $('body').on('product:afterAddToCart', function () {
            updateMiniCart = true;
        });
    },

    miniOnCartUpdate: function () {
        $('body').on('cart:update', function () {
            updateMiniCart = true;
        });
    }
};
